<template>
  <div>Loading...</div>
</template>
<script>
export default {
  methods: {
    async checkVersion () {
      const response = await fetch("https://br.collab-tv.com/version");
      const data = await response.json();
      return data.version
    }
  },
  async created () {
    const version = await this.checkVersion()
    const code = this.$route.query.code
    if (code) {
      window.location.href = `https://collab-tv.com/v${version}/index.html#/?code=${code}`
    } else {
      window.location.href = `https://collab-tv.com/v${version}/index.html`
    }
  },
  setup() {
    
  },
}
</script>

<style>
html, body {
  font-family: Arial;
}
</style>